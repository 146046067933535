#none,
#vegetarian,
#vegan,
#semi,
#pescatarian,
#paleo,
#keto {
  border: 1px solid #d9d9d9;
  border-radius: 1rem;
  margin-right: 0.5rem;
  margin-top: 0.625rem;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  text-transform: none;
}

::-webkit-scrollbar {
  width: 7px; /* width of the vertical scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #21847d; /* color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px; /* rounded corners for the scrollbar thumb */
}
