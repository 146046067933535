.label {
  color: #17527d;
}

#email {
  height: 12px;
  border-radius: 8px;
}

#password {
  height: 12px;
  border-radius: 8px;
}

#name {
  height: 12px;
  border-radius: 8px;
}

#full-name {
  color: #17527d;
}
